import "./App.scss";
import Clients from "./components/Clients/Clients";
import Contact from "./components/Contact/Contact";
import Header from "./components/Header/Header";
import Hero from "./components/Hero/Hero";
import Services from "./components/Services/Services";
import Us from "./components/Us/Us";
import Footer from "./components/Footer/Footer";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
    <div>
      <Header />
      <Hero />
      <Us />
      <Services />
      <Clients />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
